export default class Languages
{
    private config: {
        default_language: string;
        languages: string[];
        wrapper_selector: string;
        flag_size: number;
    } = {
        "default_language": "pl",
        "languages": [
            "pl",
            "en",
        ],
        "wrapper_selector": '[data-j~="languages"]',
        "flag_size": 20,
    }

    run(): void
    {
        (window as any).gtranslateSettings = this.config
        const script: HTMLScriptElement = document.createElement("script")
        script.src = "https://cdn.gtranslate.net/widgets/latest/flags.js"
        script.defer = true
        document.body.appendChild(script)
    }
}
