export default class Modal
{
    private card: HTMLElement | null = document.querySelector('[data-j~="Modal"]')
    private buttonsShow: NodeListOf<Element> = document.querySelectorAll('[data-j~="Modal.i"]')
    private buttonHide: HTMLElement | null = document.querySelector('[data-j~="Modal.hide"]')

    private target: string | null = null;
    private status: boolean = false;
    private event: CustomEvent<{ action: string | null; target: string | null }> = new CustomEvent("modal", {
        detail: {
            action: null,
            target: null,
        },
    })

    public show(): void
    {
        if (!this.status && this.target) {
            this.status = true;

            const o = document.querySelector<HTMLElement>(`[data-j~="Modal.o"][data-uuid~="${this.target}"]`)
            if (!this.card || !o) return

            this.card.style.display = "flex"
            this.card.focus()
            o.style.display = "flex"

            this.event.detail.target = this.target
            this.event.detail.action = "show"
            dispatchEvent(this.event)

            setTimeout(() => {
                o.style.transform = "translateX(0)"
            }, 250)
        }
    }

    public hide(): void
    {
        this.status = false

        const o = document.querySelectorAll<HTMLElement>('[data-j~="Modal.o"]')
        if (!this.card) return

        this.card.style.display = ""

        this.event.detail.target = this.target
        this.event.detail.action = "hide"
        dispatchEvent(this.event)

        o.forEach(e => {
            e.style.display = ""
            e.style.transform = ""
        })
    }

    public watch(): void
    {
        this.buttonsShow.forEach(e => {
            e.addEventListener("click", () => {
                const uuid = e.getAttribute("data-uuid")

                if (uuid) {
                    this.target = uuid
                    this.show()
                }
            })
        })
        if (this.buttonHide) {
            this.buttonHide.addEventListener("click", () => {
                this.hide()
            })
        }
    }
}
