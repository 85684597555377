// import Store from "./../Application/Store"
import Icon from "./../Service/Icon"
import Preloader from "./../Service/Preloader"
import Languages from "./../Service/Languages"
import Counter from "./../Service/Counter"
import Carousel from "./../Service/Carousel"
import Modal from "./../Service/Modal"
import Glitch from "./../Service/Glitch"

export default class Application
{
    private store: any

    async run(): Promise<void>
    {
        const icon: Icon = new Icon()
        icon.run()
        icon.watch()

        const preloader: Preloader = new Preloader()
        preloader.watch()

        const languages: Languages = new Languages()
        languages.run()

        const counter: Counter = new Counter()
        counter.watch()

        const carousel: Carousel = new Carousel()
        carousel.run()
        carousel.watch()

        const modal: Modal = new Modal()
        modal.watch()

        const glitch: Glitch = new Glitch()
        glitch.run()

        // const store: Store = new Store()
        // this.store = await store.get()
    }
}
